<template>
  <div class="bg-image padding p0">
    <div class="level">
      <img
        class="level-left"
        src="@/assets/img/home/logo.png"
        @click="$router.go({ name: 'Home' })"
      />
      <div class="level-right is-size-3 mr-6">Zresetuj hasło</div>
    </div>
    <hr />
    <div class="container">
      <b-loading
        :is-full-page="isFullPage"
        v-model="isLoading"
        :can-cancel="true"
      ></b-loading>
      <div class="login-box">
        <div class="title columns has-text-centered has-text-white">
          <div class="column is-12 is-size-2">Resetuj hasło</div>
        </div>
        <div class="title columns has-text-centered has-text-white">
          <div class="column is-12 is-size-4">logowanie</div>
        </div>
        <form @submit.prevent="signin">
          <div class="container content is-centered">
            <div class="is-flex is-justify-content-center pt-3">
              <figure class="image is-256x256">
                <b-icon icon="lock-reset" size="is-large"></b-icon>
                <!-- <b-image
                  :src="require(`@/assets/img/home/icons/${icon}`)"
                ></b-image> -->
              </figure>
            </div>
            <div class="columns is-vcentered pt-3">
              <div class="column is-3">
                <strong>Hasło</strong>
              </div>
              <div class="column is-9">
                <b-field>
                  <b-input
                    type="password"
                    v-model="form.password"
                    rounded
                  ></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns is-centered py-3">
              <b-button
                type="is-primary"
                rounded
                size="is-medium"
                class="button is-centered"
                @click="restorePassword"
                >Zresetuj hasło</b-button
              >
            </div>
            <b-message type="is-info" class="center" v-if="status">{{
              content
            }}</b-message>
          </div>
        </form>
      </div>
    </div>
    <section class="ftr">
      <page-footer></page-footer>
    </section>
  </div>
</template>
<script>
import Footer from "@/components/common/Footer";

export default {
  components: {
    "page-footer": Footer,
  },

  data() {
    return {
      form: {
        password: "",
        code: this.$route.params.code,
        type: this.$route.params.type,
      },
      status: false,
      isLoading: false,
      isFullPage: true,
      content: "",
    };
  },
  created() {},
  methods: {
    restorePassword() {
      if (this.form.password.length < 8) {
        this.status = true;
        this.content = "Błąd. Hasło min 8 znaków";
        return;
      }
      let payload = {
        password: this.form.password,
        code: this.form.code,
        type: this.form.type,
      };
      this.$store
        .dispatch("auth/resetPasswordFromLink", payload)
        .then((res) => {
          //console.log('res',res);
          if (res >= 400) {
            this.status = true;
            this.content = "Nie można zresetować hasła.";
          } else {
            this.status = true;
            this.content = "Hasło zresetowane";
            setTimeout(() => {
              this.$router.push({
                name: "MainAuth",
              });
            }, 500);
          }
        });
    },
  },
};
</script>
<style lang="scss">
.page-subtitle {
  position: relative;
  right: 2rem;
  color: white;
  font-weight: 700;
  font-size: 24pt;
}

.ftr {
  margin-top: 15rem;
  width: 100%;
  bottom: 0px;
}
.bg-image {
  background-image: url("./../assets/img/background/background1.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: #999;
  color: $primary;
  padding: 0;
  padding-top: 1rem;
  min-height: 50vh;

  a:hover {
    color: white;
  }

  hr {
    width: 90vw;
    height: 0.5rem;
    position: relative;
    left: 5vw;
  }
  .content {
    width: 30vw;
    min-height: fit-content;
    background-color: #ffffffc9;
    border-radius: 15px;
  }

  .header {
    position: relative;
    color: $primary;
    font-weight: 400;
    right: 1rem;
  }

  .container {
    .columns {
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }
}
</style>